<template>
  <div>
    <!-- 公共头部 -->
    <Header :bgcol="bgcol"></Header>

    <div class="section1">
      <div
        class="item"
        :class="[curIndex == 0 ? 'active' : '']"
        @click="curIndex = 0"
      >
        密码登录
      </div>
      <div
        class="item"
        :class="[curIndex == 1 ? 'active' : '']"
        @click="curIndex = 1"
      >
        短信登录
      </div>
      <div
        class="item"
        :class="[curIndex == 2 ? 'active' : '']"
        @click="curIndex = 2"
      >
        注册账号
      </div>
    </div>

    <div class="box">
      <div class="item item1" v-if="curIndex == 0">
        <div class="items">
          <img class="icon" src="@/assets/images/icon58.png" />
          <input
            type="text"
            placeholder="请输入登录手机号"
            class="input"
            v-model="loginData.tel"
          />
        </div>
        <div class="items">
          <img class="icon" src="@/assets/images/icon59.png" />
          <input
            :type="loginData.passwordType"
            placeholder="请输入密码"
            class="input"
            v-model="loginData.password"
          />
          <img
            class="icon1"
            v-if="loginData.passwordType == 0"
            src="@/assets/images/icon61.png"
            @click="changeType(0)"
          />
        </div>
        <div class="items">
          <img class="icon" src="@/assets/images/icon60.png" />
          <input
            type="text"
            placeholder="请输入验证码"
            class="input"
            v-model="loginData.verify"
          />
          <span class="icon2" @click="createCode">{{
            loginData.checkCode
          }}</span>
        </div>
        <div class="items1 clearfix">
          <div class="fl clearfix checkbox-box">
            <input
              type="checkbox"
              id="forget"
              class="checkbox fl"
              v-model="loginData.rememberPassword"
            />
            <label class="label fl" for="forget">记住密码</label>
          </div>
          <div class="fr forget" @click="showFin = true">找回密码</div>
        </div>
        <div class="submit-box" @click="login(0)">登录</div>
        <div class="xieyi">
          登录即同意碳索培平台<a class="links" @click="showPre = true"
            >《隐私政策》</a
          >
        </div>

        <div class="mask" v-if="showFin"></div>
        <div class="alert-box" :class="[showFin ? 'active' : '']">
          <h2 class="title">找回密码</h2>

          <!-- <div class="singlepage" v-html="privacydata"></div> -->
          <div class="items">
            <img class="icon" src="@/assets/images/icon58.png" />
            <input
              type="text"
              placeholder="请输入手机号"
              class="input"
              v-model="findData.tel"
            />
          </div>
          <div class="items">
            <img class="icon" src="@/assets/images/icon60.png" />
            <input
              type="text"
              placeholder="请输入验证码"
              class="input"
              v-model="findData.code"
            />
            <button
              type="button"
              class="yzm"
              @click="getCode(3)"
              :disabled="findData.disabled"
            >
              {{ findData.time }}
            </button>
          </div>
          <div class="items">
            <img class="icon" src="@/assets/images/icon59.png" />
            <input
              :type="findData.passwordType"
              placeholder="请输入密码"
              class="input"
              v-model="findData.password"
            />
            <img
              v-if="findData.showPassword"
              class="icon1"
              src="@/assets/images/icon61.png"
              @click="findChangeType(1)"
            />
            <img
              v-else-if="!findData.showPassword"
              class="icon1"
              src="@/assets/images/icon104.png"
              @click="findChangeType(1)"
            />
          </div>
          <div class="items">
            <img class="icon" src="@/assets/images/icon59.png" />
            <input
              :type="findData.repasswordType"
              placeholder="请再次输入上面的密码"
              class="input"
              v-model="findData.repassword"
            />
            <img
              v-if="findData.showPasswordAgain"
              class="icon1"
              src="@/assets/images/icon61.png"
              @click="findChangeType(2)"
            />
            <img
              v-if="!findData.showPasswordAgain"
              class="icon1"
              src="@/assets/images/icon104.png"
              @click="findChangeType(2)"
            />
          </div>

          <div class="button_group">
            <div class="btn" @click="showFin = false">取消</div>
            <div class="btn btn1" @click="login(3)">确定</div>
          </div>
        </div>
      </div>

      <div class="item" v-if="curIndex == 1">
        <div class="items">
          <img class="icon" src="@/assets/images/icon58.png" />
          <input
            type="text"
            placeholder="请输入手机号"
            class="input"
            v-model="smsData.tel"
          />
        </div>
        <div class="items">
          <img class="icon" src="@/assets/images/icon60.png" />
          <input
            type="text"
            placeholder="请输入验证码"
            class="input inputCode"
            v-model="smsData.code"
          />
          <button
            type="button"
            class="yzm"
            @click="getCode(1)"
            :disabled="smsData.disabled"
          >
            {{ smsData.time }}
          </button>
        </div>
        <div class="submit-box" @click="login(1)">登录</div>
        <div class="xieyi">
          登录即同意碳索培平台<a class="links" @click="showPre = true"
            >《隐私政策》</a
          >
        </div>
      </div>
      <div class="item item3" v-if="curIndex == 2">
        <div class="items">
          <img class="icon" src="@/assets/images/icon58.png" />
          <input
            type="text"
            placeholder="请输入手机号"
            class="input"
            v-model="registerData.tel"
          />
        </div>
        <div class="items">
          <img class="icon" src="@/assets/images/icon60.png" />
          <input
            type="text"
            placeholder="请输入验证码"
            class="input inputCode"
            v-model="registerData.code"
          />
          <button
            type="button"
            class="yzm"
            @click="getCode(2)"
            :disabled="registerData.disabled"
          >
            {{ registerData.time }}
          </button>
        </div>
        <div class="items">
          <img class="icon" src="@/assets/images/icon59.png" />
          <input
            :type="registerData.passwordType"
            placeholder="请输入密码"
            class="input"
            v-model="registerData.password"
          />
          <img
            v-if="!registerData.showPassword"
            class="icon1"
            src="@/assets/images/icon104.png"
            @click="changeType(1)"
          />
          <img
            v-if="registerData.showPassword"
            class="icon1"
            src="@/assets/images/icon61.png"
            @click="changeType(1)"
          />
        </div>
        <div class="items">
          <img class="icon" src="@/assets/images/icon59.png" />
          <input
            :type="registerData.repasswordType"
            placeholder="请再次输入上面的密码"
            class="input"
            v-model="registerData.repassword"
          />
          <img
            v-if="!registerData.showAgainPassword"
            class="icon1"
            src="@/assets/images/icon104.png"
            @click="changeType(2)"
          />
          <img
            v-if="registerData.showAgainPassword"
            class="icon1"
            src="@/assets/images/icon61.png"
            @click="changeType(2)"
          />
        </div>
        <div class="items1 clearfix">
          <div class="clearfix checkbox-box">
            <input
              type="checkbox"
              id="forget"
              class="checkbox fl"
              v-model="registerData.agreeStatus"
            />
            <label class="label fl" for="forget"
              >同意<a class="links" @click="showMask = true">《注册协议》</a
              >和<a class="links" @click="showPre = true"
                >《隐私政策》</a
              ></label
            >
          </div>

          <div class="mask" v-if="showMask"></div>
          <div class="alert-box" :class="[showMask ? 'active' : '']">
            <h2 class="title">注册协议</h2>
            <div class="singlepage" v-html="registerdata"></div>
            <div class="button_group">
              <div class="btn" @click="showMask = false">取消</div>
              <div class="btn btn1" @click="showMask = false">同意</div>
            </div>
          </div>
        </div>
        <div class="submit-box" @click="login(2)">注册</div>
      </div>
      <div class="mask" v-if="showPre"></div>
      <div class="alert-box" :class="[showPre ? 'active' : '']">
        <h2 class="title">隐私协议</h2>
        <div class="singlepage" v-html="privacydata"></div>
        <div class="button_group">
          <div class="btn" @click="showPre = false">取消</div>
          <div class="btn btn1" @click="showPre = false">同意</div>
        </div>
      </div>
    </div>

    <!-- 公共底部 -->
    <!-- <Footer></Footer> -->
  </div>
</template>

<script>
var interval = null; //倒计时函数
var interval1 = null; //倒计时函数
import Header from "@/components/Public/Header.vue";
import Footer from "@/components/Public/Footer.vue";
export default {
  components: {
    Header,
    Footer,
  },
  data() {
    return {
      curIndex: 0,
	  bgcol:"#fff",
      loginData: {
        tel: "",
        password: "",
        verify: "",
        passwordType: "password",
        rememberPassword: false,
        checkCode: "",
		
      },
      smsData: {
        tel: "",
        code: "",
        time: "获取验证码", //倒计时
        currentTime: 60,
        disabled: false,
      },
      registerData: {
        tel: "",
        code: "",
        password: "",
        repassword: "",
        agreeStatus: false,
        time: "获取验证码", //倒计时
        currentTime: 60,
        disabled: false,
        passwordType: "password",
        repasswordType: "password",
        showPassword: false,
        showAgainPassword: false,
      },
      findData: {
        tel: "",
        code: "",
        password: "",
        repassword: "",
        agreeStatus: false,
        time: "获取验证码", //倒计时
        currentTime: 60,
        disabled: false,
        passwordType: "password",
        repasswordType: "password",
        showPassword: false,
        showPasswordAgain: false,
      },

      registerdata: {},
      privacydata: {},
      backUrl: "/",
      showMask: false,
      showPre: false,
      showFin: false,
    };
  },
  created() {
    this.loadAccountInfo();
    this.getRegister();
    this.getPrivacy();
  },
  activated() {
    this.backUrl = this.$route.params.url ? this.$route.params.url : "/";
    this.curIndex = this.$route.params.curIndex ? this.$route.params.curIndex  : 0;
    this.createCode();
  },
  deactivated() {
    this.curIndex = 0;
    this.loginData = {
      tel: "",
      password: "",
      verify: "",
      passwordType: "password",
      rememberPassword: false,
      checkCode: "",
    };
    this.smsData = {
      tel: "",
      code: "",
      time: "获取验证码", //倒计时
      currentTime: 60,
      disabled: false,
    };
    this.registerData = {
      tel: "",
      code: "",
      password: "",
      repassword: "",
      agreeStatus: false,
      time: "获取验证码", //倒计时
      currentTime: 60,
      disabled: false,
      passwordType: "password",
      repasswordType: "password",
    };
  },
  methods: {

   
    // 登录 或 注册 curIndex 0账号密码登录 2注册  3 找回/修改密码
    async login(curIndex) {
      var reg = /^1[3456789]\d{9}$/;
      if (curIndex == 0) {
        var flag = false;
        var warn = "";
        var tel = this.loginData.tel;
        var password = this.loginData.password;
        var verify = this.loginData.verify;
        var checkCode = this.loginData.checkCode;
        var rememberPassword = this.loginData.rememberPassword;
        if (!reg.test(tel)) {
          warn = "请输入正确的11位手机号";
        } else if (!password) {
          warn = "请输入密码";
        } else if (password.length < 6 || password.length > 12) {
          warn = "密码长度需在6至12位之间";
        } else if (verify != checkCode) {
          warn = "图形验证码输入不正确";
        } else {
          flag = true;
          let openid = window.localStorage.getItem('openid')

          var data = {
            tel,
            password,
            verify,
          };
          if (openid) {
            data['openid'] = openid
          }
        
          this.$toast.loading("加载中...");
          const { data: res } = await this.$http.post("/login/login", data);
          this.$toast.close();
          const overDay =  7 * 24 * 60 * 60
            var timestamp = Date.now() / 1000 + overDay;
          localStorage.setItem('overTime',timestamp)
         
          if (this.$store.state.beta) {
            console.log(
              "账号密码登录：" +
                new Date().getHours() +
                ":" +
                new Date().getMinutes() +
                ":" +
                new Date().getSeconds(),
              res
            );
          }
          var code = res.code;
          if (code == 1) {
            this.$store.commit("saveUserInfo", res.data);
          
            window.localStorage.setItem('userInfo',res.data)
            this.$toast.success("登录成功");
    
            this.userInfo = res.data;
            this.loginData.tel = "";
            this.loginData.password = "";
            this.loginData.verify = "";
            this.loginData.passwordType = "password";
            this.loginData.rememberPassword = false;
            this.showMask = false;
            this.curIndex = 0;

            if (rememberPassword) {
              window.localStorage.setItem("tel", tel);
              window.localStorage.setItem("password", password);
              window.localStorage.setItem("userInfo", JSON.stringify(res.data));
            } else {
              window.localStorage.setItem("tel", "");
              window.localStorage.setItem("password", "");
            }
            var that = this;
            setTimeout(function () {
              that.$router.replace(that.backUrl);
            }, 1000);
          } else {
            this.$messageBox.alert(res.data, "温馨提示");
          }
        }
        if (!flag) {
          this.$messageBox.alert(warn, "温馨提示");
          return false;
        }
      }
      if (curIndex == 1) {
        var flag = false;
        var warn = "";
        var tel = this.smsData.tel;
        var code = this.smsData.code;
        if (!reg.test(tel)) {
          warn = "请输入正确的11位手机号";
        } else if (!code) {
          warn = "请输入手机验证码";
        } else {
          flag = true;
          var data = {
            tel,
            code,
          };
          this.$toast.loading("加载中...");
          const { data: res } = await this.$http.post("/login/codeLogin", data);
          this.$toast.close();
          if (this.$store.state.beta) {
            console.log(
              "短信登录：" +
                new Date().getHours() +
                ":" +
                new Date().getMinutes() +
                ":" +
                new Date().getSeconds(),
              res
            );
          }
          var code = res.code;
          if (code == 1) {
            clearInterval(interval1);
            this.$store.commit("saveUserInfo", res.data);
            window.localStorage.setItem('userInfo',res.data)
            this.$toast.success("登录成功");
            var that = this;
            setTimeout(function () {
              that.$router.replace(that.backUrl);
            }, 1000);
          } else {
            this.$messageBox.alert(res.data, "温馨提示");
          }
        }
        if (!flag) {
          this.$messageBox.alert(warn, "温馨提示");
          return false;
        }
      }
      if (curIndex == 2) {
        var flag = false;
        var warn = "";
        var tel = this.registerData.tel;
        var code = this.registerData.code;
        var password = this.registerData.password;
        var agreeStatus = this.registerData.agreeStatus;
        var repassword = this.registerData.repassword;
        if (!reg.test(tel)) {
          warn = "请输入正确的11位手机号";
        } else if (!code) {
          warn = "请输入手机验证码";
        } else if (!password) {
          warn = "请输入密码";
        } else if (password.length < 6 || password.length > 12) {
          warn = "密码长度需在6至12位之间";
        } else if (password != repassword) {
          warn = "2次输入的密码不一致，请重新输入";
        } else if (!agreeStatus) {
          warn = "请勾选同意《注册协议》和《隐私政策》";
        } else {
          flag = true;
          var data = {
            tel,
            code,
            password,
            repassword,
          };
          this.$toast.loading("加载中...");
          const { data: res } = await this.$http.post("/login/register", data);
          this.$toast.close();
          if (this.$store.state.beta) {
            console.log(
              "用户注册：" +
                new Date().getHours() +
                ":" +
                new Date().getMinutes() +
                ":" +
                new Date().getSeconds(),
              res
            );
          }
          var code = res.code;
          if (code == 1) {
            clearInterval(interval);
            this.$messageBox.alert(res.data + "，请使用账号密码重新登录！", {
              callback: (action) => {
                this.curIndex = 0;
                this.registerData = {
                  tel: "",
                  code: "",
                  password: "",
                  repassword: "",
                  agreeStatus: false,
                  time: "获取验证码", //倒计时
                  currentTime: 60,
                  disabled: false,
                  passwordType: "password",
                  repasswordType: "password",
                };
              },
            });
          } else {
            this.$messageBox.alert(res.data, "温馨提示");
          }
        }
        if (!flag) {
          this.$messageBox.alert(warn, "温馨提示");
          return false;
        }
      }
      if (curIndex == 3) {
        var flag = false;
        var warn = "";
        var tel = this.findData.tel;
        var code = this.findData.code;
        var password = this.findData.password;
        var agreeStatus = this.findData.agreeStatus;
        var repassword = this.findData.repassword;
        if (!reg.test(tel)) {
          warn = "请输入正确的11位手机号";
        } else if (!code) {
          warn = "请输入手机验证码";
        } else if (!password) {
          warn = "请输入密码";
        } else if (password.length < 6 || password.length > 12) {
          warn = "密码长度需在6至12位之间";
        } else if (password != repassword) {
          warn = "2次输入的密码不一致，请重新输入";
        } else {
          flag = true;
          var data = {
            tel,
            code,
            password,
            repassword,
          };
          this.$toast.loading("加载中...");
          console.log(data);
          const { data: res } = await this.$http.post("/login/retrieve", data);
          console.log(res);
          this.$toast.close();
          if (this.$store.state.beta) {
            console.log(
              "用户注册：" +
                new Date().getHours() +
                ":" +
                new Date().getMinutes() +
                ":" +
                new Date().getSeconds(),
              res
            );
          }
          var code = res.code;
          if (code == 1) {
            clearInterval(interval);
            this.$messageBox.alert("密码已重置", {
              callback: (action) => {
                this.curIndex = 0;
                this.findData = {
                  tel: "",
                  code: "",
                  password: "",
                  repassword: "",
                  agreeStatus: false,
                  time: "获取验证码", //倒计时
                  currentTime: 60,
                  disabled: false,
                  passwordType: "password",
                  repasswordType: "password",
                };
                this.showFin = false;
              },
            });
          } else {
            this.$messageBox.alert(res.data, "温馨提示");
          }
        }
        if (!flag) {
          this.$messageBox.alert(warn, "温馨提示");
          return false;
        }
      }
    },
    // 获取注册协议
    async getRegister() {
      this.$toast.loading("加载中...");
      const { data: res } = await this.$http.get("/other/danye", {
        params: { id: 2 },
      });
      // console.log(res);
      this.$toast.close();
      if (this.$store.state.beta) {
        // console.log('获取注册协议：' + new Date().getHours() + ':' + new Date().getMinutes() + ':' + new Date().getSeconds() , res);
      }
      var code = res.code;
      if (code == 1) {
        this.registerdata = res.data.content;
      } else {
        this.registerdata = "";
      }
    },
    // 获取隐私协议
    async getPrivacy() {
      this.$toast.loading("加载中...");
      const { data: res } = await this.$http.get("/other/danye", {
        params: { id: 5 },
      });
      // console.log(res);
      this.$toast.close();
      if (this.$store.state.beta) {
        // console.log('获取隐私协议：' + new Date().getHours() + ':' + new Date().getMinutes() + ':' + new Date().getSeconds() , res);
      }
      var code = res.code;
      if (code == 1) {
        this.privacydata = res.data.content;
      } else {
        this.privacydata = "";
      }
    },

    // 获取验证码
    async getCode(curIndex) {
      var tel, type;
      if (curIndex == 1) {
        tel = this.smsData.tel;
        type = 2;
      }
      if (curIndex == 2) {
        tel = this.registerData.tel;
        type = 1;
      }
      if (curIndex == 3) {
        tel = this.findData.tel;
        type = 3;
      }
      var reg = /^1[3456789]\d{9}$/;
      if (!reg.test(tel)) {
        this.$messageBox.alert("请输入正确的11位手机号", "手机格式错误");
        return false;
      }
      var data = {
        tel,
        type,
      };
      if (curIndex == 1) {
        this.smsData.disabled = true;
        var currentTime = this.smsData.currentTime;
        this.$toast.loading("加载中...");
        const { data: res } = await this.$http.post("/other/send_yzm", data);
        this.$toast.close();
        if (this.$store.state.beta) {
          console.log(
            "短信登录验证码：" +
              new Date().getHours() +
              ":" +
              new Date().getMinutes() +
              ":" +
              new Date().getSeconds(),
            res
          );
        }
        var code = res.code;
        if (code == 1) {
          this.$messageBox.alert(res.data, "温馨提示");
          var that = this;
          interval1 = setInterval(function () {
            currentTime--;
            that.smsData.time = currentTime + "秒";
            if (currentTime <= 0) {
              clearInterval(interval1);
              that.smsData.time = "重新发送";
              that.smsData.currentTime = 60;
              that.smsData.disabled = false;
            }
          }, 1000);
        } else {
          this.smsData.currentTime = 60;
          this.smsData.disabled = false;
          this.$messageBox.alert(res.data, "温馨提示");
        }
      }
      if (curIndex == 2) {
        this.registerData.disabled = true;
        var currentTime = this.registerData.currentTime;
        this.$toast.loading("加载中...");
        const { data: res } = await this.$http.post("/other/send_yzm", data);
        this.$toast.close();
        if (this.$store.state.beta) {
          console.log(
            "短信注册验证码：" +
              new Date().getHours() +
              ":" +
              new Date().getMinutes() +
              ":" +
              new Date().getSeconds(),
            res
          );
        }
        var code = res.code;
        if (code == 1) {
          this.$messageBox.alert(res.data, "温馨提示");
          var that = this;
          interval = setInterval(function () {
            currentTime--;
            that.registerData.time = currentTime + "秒";
            if (currentTime <= 0) {
              clearInterval(interval);
              that.registerData.time = "重新发送";
              that.registerData.currentTime = 60;
              that.registerData.disabled = false;
            }
          }, 1000);
        } else {
          this.registerData.currentTime = 60;
          this.registerData.disabled = false;
          this.$messageBox.alert(res.data, "温馨提示");
        }
      }
      if (curIndex == 3) {
        this.registerData.disabled = true;
        var currentTime = this.registerData.currentTime;
        this.$toast.loading("加载中...");
        const { data: res } = await this.$http.post("/other/send_yzm", data);
        this.$toast.close();
        if (this.$store.state.beta) {
          console.log(
            "短信注册验证码：" +
              new Date().getHours() +
              ":" +
              new Date().getMinutes() +
              ":" +
              new Date().getSeconds(),
            res
          );
        }
        var code = res.code;
        if (code == 1) {
          this.$messageBox.alert(res.data, "温馨提示");
          var that = this;
          interval = setInterval(function () {
            currentTime--;
            that.registerData.time = currentTime + "秒";
            if (currentTime <= 0) {
              clearInterval(interval);
              that.registerData.time = "重新发送";
              that.registerData.currentTime = 60;
              that.registerData.disabled = false;
            }
          }, 1000);
        } else {
          this.registerData.currentTime = 60;
          this.registerData.disabled = false;
          this.$messageBox.alert(res.data, "温馨提示");
        }
      }
    },
    // 修改input的type类型 index 0账号登录的密码 1注册的密码 2注册的2次密码
    changeType(index) {
      if (index == 0) {
        if (this.loginData.passwordType == "password") {
          this.loginData.passwordType = "text";
        } else {
          this.loginData.passwordType = "password";
        }
      }
      if (index == 1) {
        if (this.registerData.passwordType == "password") {
          this.registerData.passwordType = "text";
          this.registerData.showPassword = true;
        } else if (this.registerData.passwordType == "text") {
          this.registerData.passwordType = "password";
          this.registerData.showPassword = false;
        }
      }
      if (index == 2) {
        if (this.registerData.repasswordType == "password") {
          this.registerData.repasswordType = "text";
          this.registerData.showAgainPassword = true;
        } else if (this.registerData.repasswordType == "text") {
          this.registerData.repasswordType = "password";
          this.registerData.showAgainPassword = false;
        }
      }
    },
    findChangeType(index) {
      if (index == 1) {
        if (this.findData.passwordType == "password") {
          this.findData.passwordType = "text";
          this.findData.showPassword = true;
        } else {
          this.findData.showPassword = false;
          this.findData.passwordType = "password";
        }
      } else {
        if (this.findData.repasswordType == "password") {
          this.findData.repasswordType = "text";
          this.findData.showPasswordAgain = true;
        } else {
          this.findData.showPasswordAgain = false;
          this.findData.repasswordType = "password";
        }
      }
    },
    // 查询是否有记住密码
    loadAccountInfo() {
      var tel = localStorage.getItem("tel");
      var password = localStorage.getItem("password");
      if (tel && password) {
        this.loginData.tel = tel;
        this.loginData.password = password;
        this.loginData.rememberPassword = true;
      }
    },
    // 创建验证码
    createCode() {
      var code = "";
      var codeLength = 4;
      var random = new Array(1, 2, 3, 4, 5, 6, 7, 8);
      for (let i = 0; i < codeLength; i++) {
        let index = Math.floor(Math.random() * random.length);
        code += random[index];
      }
      this.loginData.checkCode = code;
    },
  },
};
</script>

<style scoped>
.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 0 0 0.3rem;
  box-shadow: 0 0 0.1rem rgba(0, 0, 0, 0.2);
  background: #ffffff;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 999;
}
.header .link {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 1rem;
}
.header .link .icon {
  height: 0.6rem;
}
.header .menu {
  width: 1rem;
  height: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
.header .menu .btn {
  width: 0.3rem;
  height: 0.3rem;
}

.nav {
  position: fixed;
  top: 1rem;
  right: 0;
  bottom: 1rem;
  z-index: 999;
  background: #ffffff;
  width: 5rem;
}
.nav .yiji {
}
.nav .yiji .item {
  padding: 0 0.3rem;
}
.nav .yiji .item .items {
  display: flex;
  align-items: center;
  height: 1rem;
}
.nav .yiji .item .items .pic {
  width: 0.34rem;
  height: 0.34rem;
}
.nav .yiji .item .items .pic .icon {
  max-height: 100%;
}
.nav .yiji .item .items .title {
  font-size: 0.26rem;
  color: #333333;
  margin-left: 0.2rem;
}

.section1 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #ffffff;
  box-shadow: 0 0 0.1rem rgba(0, 0, 0, 0.2);
}
.section1 .item {
  flex: 1;
  font-size: 0.28rem;
  color: #969897;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 0.8rem;
  transition: font-size 0.15s;
}
.section1 .item.active {
  font-size: 0.32rem;
  color: #FFFFFF;
  background: #0472c7;
  position: relative;
  
}
/* .section1 .item.active::after {
  content: "";
  display: inline-block;
  position: absolute;
  width: 0.48rem;
  height: 0.04rem;
  background: #ff6000;
  border-radius: 0.02rem;
  bottom: 0.08rem;
  left: 50%;
  transform: translate(-50%, 0);
  -ms-transform: translate(-50%, 0);
  -o-transform: translate(-50%, 0);
  -moz-transform: translate(-50%, 0);
  -webkit-transform: translate(-50%, 0);
} */

.box {
  background: #ffffff;
  box-shadow: 0 0 0.1rem rgba(0, 0, 0, 0.2);
  margin-top: 0.3rem;

  padding-bottom: 40px;
}
.box .item {
  padding: 0.4rem 0.85rem 0;
}
.box .item .items {
  position: relative;
  margin-top: 0.3rem;
}
.box .item .items:first-child {
  margin-top: 0;
}
.box .item .items .icon {
  position: absolute;
  height: 0.34rem;
  top: 0.23rem;
  left: 0.25rem;
}
.box .item .items .input {
  display: block;
  width: 100%;
  background: #f2f2f2;
  height: 0.8rem;
  border-radius: 0.5rem;
  border: none;
  padding-left: 0.84rem;
  font-size: 0.24rem;
  color: #969897;
  outline: none;
}
.box .item1 .items:nth-child(3) .input {
  width: 3.6rem;
}
.box .item .items .icon1 {
  position: absolute;
  width: 0.34rem;
  height: 0.27rem;
  top: 0.26rem;
  right: 0.3rem;
  cursor: pointer;
}
.box .item .items .icon2 {
  position: absolute;
  right: 0;
  top: 0;
  width: 2rem;
  height: 0.8rem;
  line-height: 0.8rem;
  cursor: pointer;
  background: #c90719;
  border-radius: 0.5rem;
  font-size: 0.4rem;
  color: #FFFFFF;
  text-align: center;
  font-weight: bold;
  letter-spacing: 0.06rem;
}
.box .item .items1 {
  margin-top: 0.3rem;
}
.box .item .items1 .checkbox-box {
  display: flex;
  justify-content: center;
}
.box .item .items1 .checkbox-box .checkbox {
  width: 16px;
  height: 16px;
  margin: 2px 8px 0 0;
  cursor: pointer;
}
.box .item .items1 .checkbox-box .label {
  font-size: 0.24rem;
  color: #969897;
  line-height: 0.4rem;
  cursor: pointer;
}
.box .item .items1 .forget {
  font-size: 0.28rem;
  color: #969897;
  line-height: 0.4rem;
  cursor: pointer;
}
.box .item .submit-box {
  margin-top: 0.8rem;
  width: 5.8rem;
  height: 0.8rem;
  background: #0472c7;
  font-size: 0.32rem;
  color: #ffffff;
  border-radius: 0.5rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}
.box .item .xieyi {
  font-size: 0.24rem;
  color: #969897;
  line-height: 0.4rem;
  text-align: center;
  margin-top: 0.3rem;
}
.box .item .links {
  color: #0472c7;
}
.box .item .items .yzm {
  position: absolute;
  cursor: pointer;
  font-size: 0.24rem;
  color: #0472c7;
  right: 0rem;
  top: 0rem;
  line-height: 0.4rem;
  outline: none;
  border: none;
  height: 95%;
  width: 1.5rem;
  border-radius: 8px;
  background-color: #c90719;
  color: #fff;
}
.box .item .items .inputCode {
  width: 4rem;
}
.tabbar-box {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #ffffff;
  box-shadow: 0 0 0.1rem rgba(0, 0, 0, 0.2);
  height: 1rem;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 999;
}
.tabbar-box .item {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.tabbar-box .item .pic {
  width: 0.4rem;
  height: 0.4rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
.tabbar-box .item .pic .icon {
  max-height: 100%;
}
.tabbar-box .item .title {
  font-size: 0.2rem;
  color: #c7c7c7;
  margin-top: 0.08rem;
}

.registerpage {
  width: 7rem;
  position: fixed;
  z-index: 999;
  background: #ffffff;
  top: 50%;
  left: 50%;
  border-radius: 0.14rem;
  padding: 0.4rem 0.6rem;
  transition: all 0.3s;
}

.alert-box {
  width: 7rem;
  position: fixed;
  z-index: 999;
  background: #ffffff;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scale(0);
  -ms-transform: translate(-50%, -50%) scale(0);
  -o-transform: translate(-50%, -50%) scale(0);
  -moz-transform: translate(-50%, -50%) scale(0);
  -webkit-transform: translate(-50%, -50%) scale(0);
  border-radius: 0.14rem;
  padding: 0.4rem 0.6rem;
  transition: all 0.3s;
}
.alert-box.active {
  transform: translate(-50%, -50%) scale(1);
  -ms-transform: translate(-50%, -50%) scale(1);
  -o-transform: translate(-50%, -50%) scale(1);
  -moz-transform: translate(-50%, -50%) scale(1);
  -webkit-transform: translate(-50%, -50%) scale(1);
}
.alert-box .title {
  font-size: 0.32rem;
  color: #333333;
  text-align: center;
  margin-bottom: 0.3rem;
}
.alert-box .singlepage {
  font-size: 0.28rem;
  color: #000000;
  line-height: 0.56rem;
  max-height: 5.6rem;
  overflow-y: scroll;
}
.alert-box .button_group {
  margin-top: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 0.3rem;
}
.alert-box .button_group .btn {
  width: 2rem;
  height: 0.7rem;
  background: #d4d4d4;
  border-radius: 0.5rem;
  cursor: pointer;
  font-size: 0.32rem;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
}
.alert-box .button_group .btn1 {
  background: #0472c7;
}

.alert-box1 {
  width: 4.6rem;
  position: fixed;
  z-index: 999;
  background: #ffffff;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scale(0);
  -ms-transform: translate(-50%, -50%) scale(0);
  -o-transform: translate(-50%, -50%) scale(0);
  -moz-transform: translate(-50%, -50%) scale(0);
  -webkit-transform: translate(-50%, -50%) scale(0);
  border-radius: 0.12rem;
  padding: 0.4rem;
  text-align: center;
  transition: all 0.3s;
}
.alert-box1.active {
  transform: translate(-50%, -50%) scale(1);
  -ms-transform: translate(-50%, -50%) scale(1);
  -o-transform: translate(-50%, -50%) scale(1);
  -moz-transform: translate(-50%, -50%) scale(1);
  -webkit-transform: translate(-50%, -50%) scale(1);
}
.alert-box1 .icon {
  width: 2.41rem;
  height: 1.27rem;
}
.alert-box1 .desc {
  margin-top: 0.2rem;
  margin-bottom: 0.5rem;
  font-size: 0.28rem;
  color: #000000;
  line-height: 0.44rem;
  text-align: left;
}
.alert-box1 .btn {
  display: inline-block;
  width: 1.8rem;
  height: 0.7rem;
  line-height: 0.7rem;
  background: #0472c7;
  border-radius: 0.5rem;
  cursor: pointer;
  font-size: 0.3rem;
  color: #ffffff;
}
</style>